/* src/Dashboard.css */

/* General styling for the dashboard container */
.dashboard {
  padding: 20px;
  background-color: #f7f7f7; /* Lighter background color */
  color: #333; /* Darker text color for better contrast */
  border-radius: 10px;
  text-align: center; /* Center-align the title */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Add subtle shadow for depth */
}

/* Styling for each section */
.section, .combined-section {
  margin-bottom: 30px;
  text-align: left; /* Align content to the left */
  padding: 20px;
  background-color: #e8f4fd; /* Updated to pleasant blue background color */
  border-radius: 8px; /* Rounded corners for sections */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Section shadow */
}

/* Styling for section headers */
.section h2, .combined-section h2 {
  font-size: 1.75em; /* Increased size for section titles */
  color: #000; /* Updated to black for better visibility against the gradient */
  font-weight: bold; /* Make titles bold */
  margin-bottom: 20px;
  text-align: left; /* Left-align the section titles */
}

/* Styling for icons in headers */
.section h2 svg, .combined-section h2 svg {
  margin-right: 10px;
  color: #4A90E2; /* Colorful icons */
}

/* List styling in the friends list section */
.friends-list ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.friends-list li {
  margin-bottom: 10px;
  font-size: 1.1em; /* Slightly larger font for list items */
}

/* Adjust checkbox spacing in friends list */
.friends-list input[type="checkbox"] {
  margin-right: 15px; /* Add more spacing between checkbox and friend name */
}

/* Calendar display adjustments for consistent styling and central alignment */
.calendar-display {
  width: 95%; /* Adjust width to be more prominent across the page */
  max-width: none; /* Remove maximum width constraint to allow full page width usage */
  margin: 0 auto; /* Center the calendar within its container */
  overflow-x: auto; /* Allows scrolling horizontally if the view is too wide */
  padding: 10px;
  background-color: #fff; /* Ensure calendar has a white background */
  border-radius: 12px; /* Rounded edges for a softer, modern look */
  height: 95%; /* Increased height to 95% of its container */
}

/* FullCalendar root element adjustments */
.fc {
  width: 100%; /* Ensure the calendar fills its container */
  color: #333; /* Text color updated for better visibility */
  border-radius: 12px; /* Rounded corners for internal elements */
}

/* Header row styling to highlight the days of the week */
.fc .fc-col-header {
  background-color: #e8e8e8; /* Light gray background for headers */
  border-top-left-radius: 12px; /* Rounded top-left corner */
  border-top-right-radius: 12px; /* Rounded top-right corner */
}

/* Slot styling in FullCalendar for visual spacing */
.fc .fc-timegrid-slot, .fc .fc-timegrid-slot-label {
  height: 50px; /* Increased height for a better visual spacing */
}

/* Slot border styling */
.fc .fc-timegrid-slot {
  border-bottom: 1px solid #e8e8e8; /* Lighter separator line */
}

/* Align times with the rows */
.fc .fc-timegrid-slot-label.fc-scrollgrid-shrink {
  text-align: right;
  padding-right: 15px;
  line-height: 50px; /* Align the time label vertically center */
  font-weight: bold; /* Make the time labels bold */
}

/* Style adjustments for combined "Select Date Range" and "Calendar Display" section */
.combined-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align children to the start (left) of the container */
  margin-bottom: 30px;
  padding: 20px;
  height: 100vh; /* Full viewport height */
  background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  border-radius: 8px; /* Rounded corners for aesthetic appeal */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth perception */
  text-align: left; /* Left-align the content for a balanced appearance */
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.combined-section .date-range-picker {
  margin-bottom: 20px; /* Space below the date range picker */
}

.combined-section h2 {
  margin-top: 0; /* Remove top margin for section headings */
  margin-bottom: 10px; /* Less space below the section headings */
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .dashboard, .section, .combined-section, .calendar-display {
    padding: 10px;
    margin: 5px;
    width: auto; /* Full width on mobile devices */
    border-radius: 5px; /* Smaller radius for a better fit */
  }

  .section h2, .combined-section h2 {
    font-size: 1.5em; /* Slightly smaller font size on mobile */
  }

  .friends-list li {
    font-size: 1em; /* Adjust font size for mobile readability */
  }
}
