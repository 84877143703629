.event-gradient {
    height: 100%;
    width: 100%;
    color: white;
    padding: 4px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensures padding does not add to width/height */
    background: linear-gradient(270deg, #ff5858 0%, 
    #f09819 11%, 
    #fed330 22%, 
    #26de81 33%, 
    #2bcbba 44%, 
    #45aaf2 55%, 
    #4b7bec 66%, 
    #a55eea 77%, 
    #d1d8e0 88%, 
    #ff5858 100%);
    background-size: 600% 600%;
    animation: AnimationName 6s ease infinite;
  }
  
  @keyframes AnimationName {
    0% { background-position: 0% 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }
  
  /* Optional: Style for non-magical events, if you want to differentiate */
  .event-normal {
    background: #6a6a6a; /* Default color, change as needed */
    color: white;
    height: 100%;
    width: 100%;
    background-size: 600% 600%;
  }